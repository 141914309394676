// PaymentMethodToggle.tsx
import React from 'react'
import { ToggleSwitch } from '@toasttab/buffet-pui-toggle-switches'
import { PaymentIcons } from '../PaymentIcons/PaymentIcons'
import { DividingLine } from '@toasttab/buffet-pui-config-templates'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { RadioButton } from '@toasttab/buffet-pui-radio-group'

interface Props {
  paymentType: string
  label: string
  displayPreference?: 'ON' | 'OFF'
  onToggle: () => void
  isDefault: boolean
  onRadioSelect: () => void
}

const PaymentMethodToggle: React.FC<Props> = ({
  paymentType,
  label,
  displayPreference = 'OFF',
  onToggle,
  isDefault,
  onRadioSelect
}: Props) => {
  return (
    <>
      <div className='grid grid-cols-12 items-center mb-2 mt-4 px-4'>
        <div className='flex items-center col-span-5'>
          <PaymentIcons paymentType={paymentType} />
          <span className='ml-2'>{label}</span>
        </div>
        <RadioButton
          containerClassName='col-span-5'
          label=''
          checked={isDefault}
          testId={`radio-btn-${paymentType}`}
          onChange={() => {
            // if payment method is turned off, automatically toggle on since user is setting it as default
            if (displayPreference === 'OFF') {
              onToggle()
            }
            onRadioSelect()
          }}
        />

        <div className='col-span-2 flex items-center justify-end'>
          <ToggleSwitch
            isActive={displayPreference === 'ON'}
            onChange={onToggle}
            testId={`toggle-switch-${paymentType}`}
          />
          <span className='ml-4'>
            {displayPreference === 'ON' ? 'On' : 'Off'}
          </span>
        </div>
      </div>
      {paymentType === 'applePay' && displayPreference === 'ON' ? (
        <Alert className='w-full mt-4' variant='info'>
          You must register and verify all top-level domains and subdomains
          where you’ll use Apple Pay.
        </Alert>
      ) : (
        <></>
      )}
      <DividingLine />
    </>
  )
}

export default PaymentMethodToggle
