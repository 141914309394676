import React, { useEffect, useState } from 'react'
import { DividingLine } from '@toasttab/buffet-pui-config-templates'
import PaymentMethodToggle from '../ReusableComponents/PaymentMethodToggle/PaymentMethodToggle'
import {
  DefaultPaymentMethodType,
  PMDisplayConfiguration,
  PaymentMethodKey
} from '../../typedefs/payment-method-configs/PaymentMethodConfigs'
import { InfoTooltip } from '@toasttab/buffet-pui-tooltip'
import { PAYMENT_METHOD_CONFIG } from '../PaymentMethodsComponent/PaymentMethodConstants'

type Props = {
  initialPaymentMethods: {
    [key in PaymentMethodKey]: PMDisplayConfiguration | undefined
  }
  onUpdatePaymentMethods: (updatedPaymentMethods: {
    [key in PaymentMethodKey]: PMDisplayConfiguration | undefined
  }) => void
  defaultPaymentMethod: DefaultPaymentMethodType
  onSelectDefaultPaymentMethod: (paymentType: DefaultPaymentMethodType) => void
}

const PMDisplayConfigurationComponent: React.FC<Props> = ({
  initialPaymentMethods,
  onUpdatePaymentMethods,
  defaultPaymentMethod,
  onSelectDefaultPaymentMethod
}: Props) => {
  const [paymentMethods, setPaymentMethods] = useState(initialPaymentMethods)

  useEffect(() => {
    setPaymentMethods(initialPaymentMethods)
  }, [initialPaymentMethods])

  const handleToggle = (paymentType: PaymentMethodKey) => {
    const updatedPaymentMethods = {
      ...paymentMethods,
      [paymentType]: {
        ...paymentMethods[paymentType]!,
        displayPreference:
          paymentMethods[paymentType]?.displayPreference === 'ON' ? 'OFF' : 'ON'
      }
    }
    setPaymentMethods(updatedPaymentMethods)
    onUpdatePaymentMethods(updatedPaymentMethods)
  }

  return (
    <div>
      <div className='type-subhead text-secondary -mb-4 mt-6 grid grid-cols-12 items-center'>
        <div className='col-span-5'>Payment Methods</div>
        <div className='flex items-center col-span-5'>
          Make default
          <InfoTooltip className='pl-1' size='sm' placement='right' cropToIcon>
            {PAYMENT_METHOD_CONFIG.DEFAULT_PAYMENT_METHOD_TOOLTIP}
          </InfoTooltip>
        </div>
        <div className='col-span-2'>On / Off</div>
      </div>
      <DividingLine />
      <PaymentMethodToggle
        paymentType='applePay'
        label='Apple Pay'
        displayPreference={paymentMethods?.applePay?.displayPreference}
        onToggle={() => handleToggle('applePay')}
        isDefault={defaultPaymentMethod === DefaultPaymentMethodType.APPLE_PAY}
        onRadioSelect={() =>
          onSelectDefaultPaymentMethod(DefaultPaymentMethodType.APPLE_PAY)
        }
      />
      <PaymentMethodToggle
        paymentType='googlePay'
        label='Google Pay'
        displayPreference={paymentMethods?.googlePay?.displayPreference}
        onToggle={() => handleToggle('googlePay')}
        isDefault={defaultPaymentMethod === DefaultPaymentMethodType.GOOGLE_PAY}
        onRadioSelect={() =>
          onSelectDefaultPaymentMethod(DefaultPaymentMethodType.GOOGLE_PAY)
        }
      />
      <PaymentMethodToggle
        paymentType='card'
        label='Keyed-In cards'
        displayPreference={paymentMethods?.card?.displayPreference}
        onToggle={() => handleToggle('card')}
        isDefault={
          !defaultPaymentMethod ||
          defaultPaymentMethod === DefaultPaymentMethodType.CARD
        }
        onRadioSelect={() =>
          onSelectDefaultPaymentMethod(DefaultPaymentMethodType.CARD)
        }
      />
    </div>
  )
}

export default PMDisplayConfigurationComponent
